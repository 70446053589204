/* color palette: https://colorhunt.co/palette/fcf8e894b49fdf786176549a
rgb(44, 54, 57)     - dark1
rgb(63, 78, 79)     - dark2
rgb(162, 123, 92)   - brown
rgb(220, 215, 201)  - light
*/
*:not(path){
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: beige;
}
html {
    background-color: rgb(44, 54, 57);
    scroll-behavior: smooth;
}
body{
    margin: 0;
    overflow-x: hidden;
}
.root{
    height: 100%;
}

.MainContent{
    width: 100vw;
    min-height: 400vh;
    z-index: -1;
}
.typing-span{
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    text-align: center;
}
/* elements above fold */

.above-fold{
    padding-top: 10vh;
    height: 90vh;
    z-index: -10;
}
.intro-text{
    position: absolute;
    top: 30%;
    margin-left: 15vw;
    /* translate for fade in effect */
    transform: translate(-100%); 
    
    transition: 1s all cubic-bezier(.5,2,0.5,.8);
}
.intro-text-miniAbout{
    font-size: 2rem;
    position: absolute;
    top: 50%;
    margin: 0 15vw 0 50vw;
    text-align: right;
    /* translate and opacity for fade in effect */
    transform: translateY(200%);
    opacity: 0;

    transition: 1s all cubic-bezier(.5,2,0.5,.8);
    
}
.intro-text-miniAbout h4{
    margin: 0;
}
.intro-text-miniAbout button{
    background-color: rgb(79, 79, 121);
    width: 150px;
    height: 40px;
    align-self: flex-start;
    margin-top: 30px;

    font-size: 1.1rem;
    border: 1px solid rgb(255, 255, 255);
    background-color: rgb(36, 45, 47);
    color: bisque;
    border-radius: 3px;

    transition: 0.5s all;
}
.intro-text-miniAbout button:hover{
    background-color: bisque;
    color: rgb(36, 45, 47);
    border: 3px solid rgb(69, 69, 69);
}
.intro-text h6{
    font-size: 5rem;
    margin: 0;
    transition: 1s all cubic-bezier(0.23, 1, 0.320, 1);
}
.intro-text-hovered{
    transform:scaleY(1.4);
    /* transform: translate(30%); */
    color: rgb(190, 190, 151);
}
.intro-text-second{
    font-size: 4.5rem;
}
/* canvas for cursor effect */
#myCanvas{
    position: absolute;
    width: 100vw;
    height: 160vh;
    top: -60vh;
    z-index: -100;
}

/* -------------------- side sections for gmail -------------------- */
.gmail-dash a{
    text-decoration: none;
    font-size: 0.8rem;
    position: fixed;
    bottom: 235px;
    right: 0vw;

    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);

    letter-spacing: 3px;
    z-index: 100;

    transition: 1s all;
}
.gmail-dash a:hover{
    color: #749EB2;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);

    transform: translate(0%, -100%) rotate(90deg);
}
.dash-for-gmail{
    position: fixed;
    bottom: -2vh;
    right: 0vw;
    background-color: black;
    height: 2px;
    width: 240px;
    transform: rotate(90deg);
}

/* -------------------- side sections for socials -------------------- */
.socials-holder{
    position: fixed;
    bottom: 0vh;
    left: 8vw;

    z-index: 100;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.gmail-dash a:hover{
    color: #749EB2;
}
.dash-for-socials{
    background-color: black;
    height: 100px;
    width: 2px;
    margin-left: 14.5px;
}
.socials-holder > svg:nth-child(-n+3){
    transition: 1s all;
    color: beige;
}
.socials-holder > svg:nth-child(-n+3):hover{
    transform: scale(1.1);
    color: #749EB2;
}
.navigating-buttons{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}
.emailCopied{
    z-index: 5;
    text-align: center;
    position: fixed;
    font-size: 1.8rem;
    top: 10vh;
    background-color: #80808045;
    width: 100%;
    padding: 5px;

    transition: 0.5s all;
}


/* Mobile users css */
@media (max-width: 480px) {
    .intro-text h6{
        font-size: 3rem;
    }
    .intro-text{
        margin-top: -8vh;
        margin-left: 5vw;
    }



    .navigating-buttons{
        width: 90vw;
    }

    .intro-text-miniAbout{
        font-size: 1.4rem;
        margin: 8vh 5vw 0 5vw !important;

    }
    .gmail-dash{
        visibility:hidden;
    }
    .socials-dash{
        visibility: hidden;
    }
  }
@media (max-width: 992px) {
    .intro-text h6{
        font-size: 3.8rem;
    }
    .intro-text{
        margin-left: 5vw;
    }
    .intro-text-miniAbout{
        font-size: 1.4rem;
        margin: 0 5vw 0 10vw;
        width: 85vw;

    }
    .gmail-dash{
        visibility:hidden;
    }
    .socials-dash{
        visibility: hidden;
    }
}
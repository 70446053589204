.projects{
    min-height: 1860px;
    margin-bottom: 30px;
}
.BashTerminal-transition-backToGrid{
    transition: 1s all;
}
.projects h4{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin-top: 3vh;
}
.projects-holder{
    width: 60vw;
    margin-left: 20vw;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    gap: 10px;
    align-items: center;
    justify-items: center;
}
.projects-holder img{
    width: 100%;
    height: auto;
}
.single-project-holder{
    width: 100%;
    border: 1px solid white;
    margin: 20px 0;
    padding: 5px;
}
.projectAbout{
    transform: scale(0) rotate(90deg);

    transition: 1s all;
    border-left: 0;
    border-right: 0;
    min-height: 400px;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.projectAbout ul{
    list-style-type: none;
}
.minimized-BashTerminal-preAnim{
    position: fixed !important;
}
.minimized-BashTerminal{
    position: fixed !important;
    bottom: -200px !important;
    right: -200px !important;
    transform: rotate(-45deg) scale(0.3) !important;

    transition: 1s all;
}
.BashTerminal{
    margin: 5px 0;
    width: 100%;
    height: 50vh;
    background-color: rgb(26, 26, 26);
    border-radius: 15px;
    box-shadow: 10px 20px 20px 5px rgba(0, 0, 0, 0.282);

    transform: scale(0) rotate(90deg);
}
.popAnim{
    transform: scale(1) rotate(0deg);
}
.popAnim-preAnim{
    transition: 1s all;
}
.terminal{
    height: 85%;
    overflow: scroll;
}
.BashTerminal .upper-stripe{
    width: 100%;
    height: 15%;
    background-color: gray;
    border-radius: 15px 15px 0 0;
    position: sticky;

    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr;
}
.BashTerminal-name{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.BashTerminal-buttons{
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    gap: 6px;
}
.upper-stripe .red{
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 20px;
    margin-left: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.red .red-dot{
    background-color: rgb(25, 25, 25);
    width: 5px;
    height: 5px;
    border-radius: 5px;
}
.upper-stripe .green{
    width: 15px;
    height: 15px;
    background-color: green;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.green svg{
    margin-top: 2px;
    margin-left: 3px;
}
.upper-stripe .orange{
    width: 15px;
    height: 15px;
    background-color: orange;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.orange-dash{
    background-color: rgb(25, 25, 25);
    width: 60%;
    height: 1.5px;
}


.curlyBraces{
    width: 100%;
    height: 100%;
}
.groupPoject{
    width: 100%;
    height: 100%;
}
.coinTrace{
    width: 100%;
    height: 100%;
}
.battleships{
    width: 100%;
    height: 100%;
}

.projectAbout-name{
    margin-top: 20px;
    text-align: center;
    font-size: 2.5rem;
}
.projectAbout-technologiesHeading{
    font-weight: bold;
    font-size: 1.2rem;
}
.projectAbout-technologies{
    padding: 0;
}
.projectAbout-view{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    background-color: rgba(128, 128, 128, 0.448);
    border-radius: 5px;
    padding: 10px;
}
.sorting-variants{
    width: 100vw;
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.projectSort-button{
    background-color: rgba(0, 0, 0, 0.0);
    font-size: 1.5rem;
    border: 2px solid rgb(36, 36, 32);
}
.projectSort-button:focus{
    border: 4px solid rgb(36, 36, 32);
}
@media (max-width: 635px){
    .projects-holder{
        grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
    }
}
header{
    height: 10vh;
    width: 90%;
    position: fixed;
    background-color: rgb(44, 54, 57);

    transition: 1s all;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0% 5%;
    z-index: 999;
}
.header span{
    font-size: 1.5rem;
}
.header-nav ul{
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    gap: 5vw;
    list-style: none;
}
.header-nav button{
    outline: none;
    border: none;
    background-color: inherit;
    font-size: 1.1rem;
}

.header-nav button{
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    border-bottom: 0px solid rgb(162, 140, 121);
}
.header-nav button:hover{
    transform: scale(1.05);
    border-bottom: 2px solid rgb(162, 123, 92);
    color: rgb(194, 194, 173);
}
.headerDivider{
    position: fixed;
    margin-top: 10vh;
    color: beige;
    width: 100vw;
    margin-left: -5vw;
}